import {
  Container, Grid, Text,
  Table,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImagesThunk, deleteImageThunk, getAllActiveCategoriesThunk, getImageDetailsThunk } from "../../Redux/Thunks/imageThunk";
import { imageSelector } from "../../Redux/Selectors/imageSelector";
import { Button } from "@mui/material";
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import AlertDialog from "../Dialog/Alert";
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { setIsLoggedIn } from "../../Redux/userSlice";
import { imageEmpty, updateValidationError } from "../../Redux/imageSlice";
import axios from "axios";
import { Toggle } from "rsuite";
import { HashLoader } from "react-spinners";



const imagefolder = "images"

const Main = () => {

  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT

  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { images, totalImages }: any = useSelector(imageSelector)
  const [toggle, setToggle] = useState<any>(true)
  const [showCheckBox, setshowCheckBox] = useState<any>(false)
  const [feactureUpdate, setFeactureUpdate] = useState<any>(toggle)
  const [imagesIds, setImagesIds] = useState<any>([])
  const [isLoading, setLoading] = useState(false);

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }


  useEffect(() => {
    async function getData() {
      let token = checkLoginToken()

      let response: any = await dispatch(getAllActiveCategoriesThunk({ token: token }))
      if (response.payload.response && response.payload.response.status) {
        if (response.payload.response.status == 401) {
          logoutUser()
        }
        toast.error(response.payload.response.data.message)
      }
    }
    getData()
  }, [])

  async function getData(data: any) {
    let token = checkLoginToken()
    setLoading(true)
    let response: any = await dispatch(getImagesThunk(
      {
        page: data.page,
        limit: data.limit,
        token: token
      }
    ))
    if (response.payload.response && response.payload.response.status) {
      setLoading(false)
      if (response.payload.response.status == 401) {
        setLoading(false)
        logoutUser()
      }
      toast.error(response.payload.response.data.message)
    }
    else {
      setLoading(false)
      pageCounterr()
    }
  }

  useEffect(() => {
    getData({
      page: selectedPage,
      limit: limit
    })
  }, [feactureUpdate])

  const handleEdit = async (e: any, data: any) => {
    e.preventDefault()
    let token = checkLoginToken()
    let response: any = await dispatch(getImageDetailsThunk({ token: token, image_id: data._id }))
    if (response.payload.response && response.payload.response.status) {
      if (response.payload.response.status == 401) {
        logoutUser()
      }
      toast.error(response.payload.response.data.message)
    }
    dispatch(updateValidationError(false))
    navigate("/image/addedit", { state: data })
  }

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [elementId, setElementId] = useState("")

  const handleDeleteModal = async (e: any, _id: any) => {
    setDeleteDialog(true)
    setElementId(_id)
  }

  const handleDeleteImage = async (id: any) => {
    try {
      let token = checkLoginToken()

      let payload = {
        _id: id,
        token: token
      }
      let response: any = await dispatch(deleteImageThunk(payload))

      if (response.payload.response && response.payload.response.status) {
        if (response.payload.response.status == 401) {
          logoutUser()
        }
        toast.error(response.payload.response.data.message)
      }


      if (response.payload.data && response.payload.data.success) {
        toast.success(response.payload.data.message)
        await getData({
          page: selectedPage,
          limit: limit
        })
        setDeleteDialog(false)
        setElementId("")
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateFeature = async (id: any, bool: boolean) => {
    let token = checkLoginToken()

    // setToggle(!toggle)
    const res = await axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/image/featureUpdate/${id}`,
      { isFeatured: bool }, { headers: { "Authorization": `${token}` } })
   
    getData({
      page: selectedPage,
      limit: limit
    })
  }
  const updatePremium = async (id: any, bool: boolean) => {
    let token = checkLoginToken()

    // setToggle(!toggle)
    debugger
    const res = await axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/image/premiumUpdate/${id}`,
      { isPremium: bool }, { headers: { "Authorization": `${token}` } })
   
    getData({
      page: selectedPage,
      limit: limit
    })
  }
  const handleCheckBox = (e: any) => {
    let value = e.target.value
    let checked = e.target.checked
    if (checked) {
      setImagesIds((oldItems: any) => [...oldItems, value])

    } else {
      setImagesIds((oldItems: any) => oldItems.filter((v: any) => v !== value));

    }

  }
  const deleteMultiples = async (bool: any) => {
    let token = checkLoginToken()
    let url = `${process.env.REACT_APP_BACKEND_API_BASE_URL}/image/deleteMultiple`
    let body = { images: imagesIds }

    const res = await axios.delete(url,
      { headers: { "Authorization": `${token}` }, data: body })

    getData({
      page: selectedPage,
      limit: limit
    })
    if (res.status == 200) {
      setDeleteDialog(bool)
    }

  }
  console.log("cdfsfeswfedf", imagesIds);
  const rows = images && images.map((row: any, index: any) => (
    <tr key={row._id}>
      <td style={{ display: showCheckBox ? "" : "none" }}><input style={{ cursor: "pointer" }} type="checkbox" value={row._id} onChange={handleCheckBox} /> </td>
      <td style={{ display: !showCheckBox ? "" : "none" }}>{((selectedPage - 1) * limit) + index + 1}.</td>

      <td>{row.image_hd ?
        <img src={(process.env.REACT_APP_BACKEND_BASE_URL + `uploads/${imagefolder}/` + row.image_hd)} alt={row.image_hd} height="80px" />
        : "Not found"}
      </td>
      <td>{row.image_low ?
        <img src={(process.env.REACT_APP_BACKEND_BASE_URL + `uploads/${imagefolder}/` + row.image_low)} alt={row.image_low} height="80px" />
        : "Not found"}
      </td>
      {/* <td>{row.name}</td> */}

      <td>{row.publisher}</td>
      <td>
        <div style={{
          display: 'block', width: 400, paddingLeft: 30
        }}>

          <Toggle
            onChange={(value) => { updateFeature(row._id, !row.isFeatured) }}
            defaultChecked={row.isFeatured}
          />
          {/* {row.isFeatured === true ? "Toggle On" : "Toggle Off"} */}
        </div>

        {/* <button
          onClick={(e: any) => {
          
            updateFeature(row._id, !row.isFeatured)
         

          }}>{row.isFeatured ? "Featured" : "Not Featured"}
        </button> */}
      </td>
      <td>
        <div style={{
          display: 'block', width: 400, paddingLeft: 30
        }}>

          <Toggle
            onChange={(value) => { updatePremium(row._id, !row.isPremium) }}
            defaultChecked={row.isPremium}
          />
          {/* {row.isFeatured === true ? "Toggle On" : "Toggle Off"} */}
        </div>

    
      </td>
      <td>{row.createdAt.split("T")[0]}</td>
      <td>{row.updatedAt.split("T")[0]}</td>
      <td>
        <EditSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) => handleEdit(e, row)} />
        <DeleteSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
          handleDeleteModal(e, row._id)
        } />
      </td>
    </tr>
  ));

  const [pageCount, setPageCount] = useState(1);

  const pageCounterr = () => {
    const pageCounter: any = Math.ceil(parseInt(totalImages) / parseInt(limit))
    setPageCount(pageCounter)
  }

  const handlePageClick = async (data: any) => {
    setSelectedPage(+data.selected + 1)
    await getData({ page: +data.selected + 1, limit: 10 })
  }

  useEffect(() => {
    pageCounterr()
  }, [totalImages])

  useEffect(() => {
    if ((!images || images.length == 0) && selectedPage > 1) {
      setSelectedPage(selectedPage - 1)

      getData({
        page: selectedPage - 1,
        limit: limit
      })

    }
  }, [images])



  return (

    <>
    
      <div className="main_layout">
        <h2>Images
          <button className="btn add_btn" color="warning" onClick={async () => {
            dispatch(imageEmpty())
            dispatch(updateValidationError(true))
            navigate("/image/addedit")
          }}>Add Image</button>

        </h2>

        <div className="dlte_btn">
          <button className="add_btn " onClick={(e) => {
            handleDeleteModal(e, "")
            deleteMultiples(false)
          }
          } style={{ display: showCheckBox ? "" : "none" }}>Delete</button>
          <button className="add_btn " onClick={() => setshowCheckBox(!showCheckBox)}>{showCheckBox ? "Cancel" : "Delete multiple"} </button>
        </div>
        <div className="table-responsive check_table">
          <table>
            <thead>
              <tr>
                <th style={{ display: showCheckBox ? "" : "none" }}>Select</th>
                <th style={{ display: !showCheckBox ? "" : "none" }}>S.No</th>
                <th>HD Resolution Image</th>
                <th>Low Resolution Image</th>
                {/* <th>Name</th> */}
                <th>Publisher</th>
                <th>Featured</th>
                <th>Premium</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(rows && rows.length >= 0) && (
                rows
              )
              }
            </tbody>
          </table>
        </div>

        <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
          handleDeleteImage(elementId)
          deleteMultiples(false)
        }} />

        {
          (images && images.length > 0) && (
            <ReactPaginate

              previousLabel={"<"}
              nextLabel={">"}
              //  breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"pagination_prev"}
              previousLinkClassName={"page-link"}
              nextClassName={"pagination_next"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={selectedPage - 1}
            />
          )
        }

      </div>
        
    </>
    // </Container>
  );
};

export default Main;



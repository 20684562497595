import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getImagesThunk = createAsyncThunk(
    'image/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/image/all?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })

            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getImageDetailsThunk = createAsyncThunk(
    'image/details',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/image/details/${body.image_id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const updateFeatureThunk = createAsyncThunk(
    'image/updateFeature',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/image/featureUpdate/${body.image_id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const updatePremiumThunk = createAsyncThunk(
    'image/updatePremium',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/image/premiumUpdate/${body.image_id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addImageThunk = createAsyncThunk(
    "image/create",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            delete body.token
            console.log(body)
            const data = await axios.post(`${api_url}/image/create`, body, { 'headers': { 'Authorization': `${token}` } })
           
            
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteImageThunk = createAsyncThunk(
    "image/delete",
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.delete(`${api_url}/image/delete/${body._id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getAllActiveCategoriesThunk = createAsyncThunk(
    'images/active/categories',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/subcategory/activeCategories`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getAllActiveSubCategoriesThunk = createAsyncThunk(
    'images/active/subcategories',
    async (body: any, { rejectWithValue }) => {
        try {
            console.log("API CALLED")
            const data = await axios.get(`${api_url}/subcategory/activeSubcategories?category_id=${body.category_id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


